import React from 'react';
import { Nav } from './nav';
import { Logo } from '../components/logo';
export const MainSite = () => (
    <div className="h-100 position-relative">
        <Nav />
        <div onTouchStart="" className="logo-container d-flex justify-content-center align-items-center">
            <div className="word w-1">
                OBSECURE
            </div>
            <div className="word w-2">
                CONCEAL
            </div>
            <div className="word w-3">
                MASK
            </div>
            <div className="word w-4">
                MANTLE
            </div>
            <div className="word w-5">
                OUTLANDER
            </div>
            <div className="word w-6">
                CAMOUFLAGE
            </div>
            <div className="word w-7">
                ARMOR
            </div>
            <div className="word w-8">
                VEIL
            </div>
            <div className="word w-9">
                SHIELD
            </div>
            <div className="word w-10">
                INVISIBLE
            </div>
            <div className="word w-11">
                DISGUISE
            </div>
            <div className="word w-12">
                PROTECTION
            </div>
            <Logo />
        </div>
    </div>
);