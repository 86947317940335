import React from 'react';
import carson from '../assets/images/carson-logo.png';

export const Logo = () => (
    <>
        <div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={carson} className="img-fluid logo-img" alt="logo" />
                <div className="d-lg-none">
                    <div className="logo-text">TAP AROUND TO FIND YOURSELF</div>
                </div>
                <div className="d-none d-lg-block d-xl-none">
                    <div className="logo-text">HOVER/TAP AROUND TO FIND YOURSELF</div>
                </div>
                <div className="d-none d-xl-block">
                    <div className="logo-text">HOVER AROUND TO FIND YOURSELF</div>
                </div>
            </div>
        </div>
    </>
);



