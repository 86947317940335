import React from 'react';
import './App.scss';
import { MainSite } from './containers/main-site';

const App = () => (
  <div style={{ height: '100vh' }}>
    <MainSite />
  </div>
);

export default App;
