import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faTwitter,
  faYoutube,
  faSoundcloud,
  faApple,
  faSpotify,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';

export const Socials = () => (
  <>
    <a href="https://www.instagram.com/middlenamecarson/" target="_blank">
      <FontAwesomeIcon icon={faInstagram} className="mr-4" size="lg" />
    </a>
    <a href="https://www.twitter.com/midnamecarson" target="_blank">
      <FontAwesomeIcon icon={faTwitter} className="mr-4" size="lg" />
    </a>
    <a href="https://www.facebook.com/middlenamecarson" target="_blank">
      <FontAwesomeIcon icon={faFacebookF} className="mr-4" size="lg" />
    </a>
    <a
      href="https://www.youtube.com/channel/UCFB6qWO6xY3PPXchdk9mzTg"
      target="_blank"
    >
      <FontAwesomeIcon icon={faYoutube} className="mr-4" size="lg" />
    </a>
    <a href="https://www.soundcloud.com/middlenamecarson" target="_blank">
      <FontAwesomeIcon icon={faSoundcloud} className="mr-4" size="lg" />
    </a>
    <a href="http://itunes.apple.com/album/id/1562467235" target="_blank">
      <FontAwesomeIcon icon={faApple} className="mr-4" size="lg" />
    </a>
    <a
      href="https://open.spotify.com/album/7H2LnBcIVkgIu5NitEB564"
      target="_blank"
    >
      <FontAwesomeIcon icon={faSpotify} size="lg" />
    </a>
  </>
);
