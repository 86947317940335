import React from 'react';
import { Socials } from '../components/socials';

export const Nav = () => (
    <>
        <div className="d-block d-sm-none">
            <div className="d-flex flex-column justify-content-center align-items-center pt-4 pr-2 pl-2">
                <div>
                    <a href="mailto:aoutrageous@gmail.com?Subject=MiddleNameCarson"><h4>CONTACT</h4></a>
                </div>
                <div className="m-3">
                    <Socials />
                </div>
            </div>
        </div>
        <div className="d-none d-sm-block">
            <div className="d-flex flex-row justify-content-between p-4">
                <div>
                    <a href="mailto:aoutrageous@gmail.com?Subject=MiddleNameCarson"><h4>CONTACT</h4></a>
                </div>
                <div>
                    <Socials />
                </div>
            </div>
        </div>
    </>
);
